/**
 * WARNING: DO NOT EDIT UNLESS YOU ARE IN THE ROOT SHARED FOLDER -- NOT A SUBDIRECTORY
 */

const configMap = {
	dev: {
		graphqlConfig: {
			api_endpoint: "https://multi-region-dev-graphql.joinswsh.com/graphql",
			fallback_endpoint: "https://multi-region-dev-graphql-fallback.joinswsh.com/graphql",
		},
		amplitudeConfig: {
			key: "8b5b81d1f07db18977003f31c54ebb07",
		},
		resources: {
			publicUrl: "https://d3m7qshrtksb83.cloudfront.net",
			restrictedUrl: "https://divh3me4nh6zx.cloudfront.net",
		},
		skiaStatic: {
			publicUrl: "https://dnxli7taowvvk.cloudfront.net",
		},
		publicApiUrl: "https://dev-public-api.joinswsh.com",
		webUrl: "https://dev-web.joinswsh.com",
		share: {
			base: "https://dev-web.joinswsh.com",
		},
		Meta: {
			appId: "874326667647681",
		},
	},
	staging: {
		graphqlConfig: {
			api_endpoint: "https://staging-graphql.joinswsh.com/graphql",
			fallback_endpoint: "https://staging-graphql-fallback.joinswsh.com/graphql",
		},
		resources: {
			publicUrl: "https://d22jjx0zy8vfi7.cloudfront.net",
			restrictedUrl: "https://du0oboi77cdni.cloudfront.net",
		},
		skiaStatic: {
			publicUrl: "https://d2w7fjvrhn7nty.cloudfront.net",
		},
		publicApiUrl: "https://staging-public-api.joinswsh.com",
		webUrl: "https://staging-web.joinswsh.com",
		share: {
			base: "https://staging-web.joinswsh.com",
		},
		amplitudeConfig: {
			key: "298e444cbaacbe04fba0a8c30f33d199",
		},
		Meta: {
			appId: "874326667647681",
		},
	},
	prod: {
		graphqlConfig: {
			api_endpoint: "https://prod-graphql.joinswsh.com/graphql",
			fallback_endpoint: "https://prod-graphql-fallback.joinswsh.com/graphql",
		},
		amplitudeConfig: {
			key: "e0907100bd930e05f76f7e28331d0f73",
		},
		resources: {
			publicUrl: "https://d22avi8y4kn5m5.cloudfront.net",
			restrictedUrl: "https://d7oe4osw6bewp.cloudfront.net",
		},
		skiaStatic: {
			publicUrl: "https://d2jdc92l98i1d6.cloudfront.net",
		},
		publicApiUrl: "https://public-api.joinswsh.com",
		webUrl: "https://www.joinswsh.com",
		share: {
			base: "https://joinswsh.com",
		},
		Meta: {
			appId: "874326667647681",
		},
	},
	missing: {
		graphqlConfig: {
			api_endpoint: "",
			fallback_endpoint: "",
		},
		amplitudeConfig: {
			key: "",
		},
		resources: {
			publicUrl: "",
			restrictedUrl: "",
		},
		skiaStatic: {
			publicUrl: "",
		},
		publicApiUrl: "",
		share: {
			base: "",
		},
		Meta: {
			appId: "",
		},
	},
};
export default configMap;
