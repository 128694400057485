import type { ReactNativeOptions } from "@sentry/react-native";
import { curEnv } from "src/config";
import clientVersion from "src/constants/clientVersion";

const sentryConfig = {
	debug: false,
	tracesSampleRate: 1,
	environment: curEnv,
	enabled: curEnv !== "dev",
	release: clientVersion,
	beforeSend: (event) => {
		if (event.extra?.fwd === "Pino") {
			if (event.exception) {
				for (const value of event.exception.values ?? []) {
					// First frame in stacktrace is the log function, remove it
					value.stacktrace?.frames?.shift();
				}
			}
		}

		return event;
	},
} satisfies ReactNativeOptions;
export default sentryConfig;
