"use client";
import type { init as initType } from "@sentry/browser";
import { useEffect } from "react";
import { NEXT_SENTRY_DSN } from "./constants";
import sentryConfig from "./sentryConfig";

const sentryLazyInit = async () => {
	// @ts-expect-error
	const init = (await import("@sentry/browser/esm/sdk")).init as typeof initType;

	init({
		...sentryConfig,
		dsn: NEXT_SENTRY_DSN,
	});
};

export const SentryLazyInitComponent = () => {
	useEffect(() => {
		sentryLazyInit();
	}, []);
	return null;
};

export default sentryLazyInit;
